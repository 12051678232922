<template>
  <div class="raportit-selaa">
    <!-- Top bar -->
    <ViewSelaaTopBarBase
      :pikahaun-placeholder-teksti="$t('common:reportsBrowse.quickSearch')"
      :vuex-module-name="vuexModuleName"
      @open-search-panel="openSearchPanel"
      @close-search-panel="closeSearchPanel"
    />

    <!-- Advanced search -->
    <TransitionSlidePanel>
      <div
        v-show="searchPanelOpen"
        class="pa-1 primary darken-1 shadow--inset"
      >
        <FormSearchRaportit v-model="searchTerms" />
      </div>
    </TransitionSlidePanel>

    <!-- Toolbar -->
    <ViewSelaaToolbar
      :vuex-module-name="vuexModuleName"
      item-partitiivi="kertaraporttia"
      :alt-selaa-btn-config="{
        toName: 'raportit-kuukausiraportti-selaa',
        itemPartitiivi: 'kuukausiraportteja',
        permission: onkoLupaLuodaKuukausiraportti,
      }"
      :uusi-btn-config="{
        toName: 'raportit-uusi',
        itemName: 'kertaraportti',
      }"
    />

    <!-- Show items as table -->
    <v-data-table
      class="v-data-table--striped"
      :headers="tableHeaders"
      :items="items"
      :hide-default-footer="items.length < 10"
      :footer-props="{
        itemsPerPageOptions: [10, 25, 50, 100],
      }"
      :multi-sort="true"
      :server-items-length="itemsTotal"
      :options.sync="pagination"
      :loading="loading"
      loading-text=""
      no-data-text=""
      :dense="tiivisNakyma"
    >
      <template v-slot:progress>
        <v-progress-linear
          color="primary"
          indeterminate
        />
      </template>
      <template v-slot:item="{ item }">
        <TableRow
          :clickable="(true)"
          :data="item"
          :fade="loading"
          @click="lataaRaportti(item)"
        >
          <td class="text-no-wrap">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <StatusIcon
                  v-if="item.ikoni_ja_vari"
                  :icon="item.ikoni_ja_vari.ikoni"
                  :size="tiivisNakyma ? 'small' : 'medium'"
                  :hover-icon="('file-download')"
                  :bg-color="item.ikoni_ja_vari.vari"
                  :event-listener="on"
                  @click="lataaRaportti(item)"
                />
              </template>
              <template v-slot:default>
                <span>{{ $t('common:raportitSelaa.loadReport') }}</span>
              </template>
            </v-tooltip>
          </td>
          <td class="text-no-wrap">
            {{ item.id }}
          </td>
          <td class="max-width text-wrap">
            <span
              v-for="(pm, index) of item.paamiehet"
              :key="pm.id"
            ><span v-if="index !== 0"><br> </span>{{ `${pm.nimi} (${pm.id})` }}</span>
          </td>
          <td class="text-no-wrap">
            {{ item.raportti }}
          </td>
          <td class="text-no-wrap text-right">
            {{ $date(item.luotu) }}
          </td>
          <td class="text-no-wrap">
            {{ item.tila }}
          </td>
          <td class="text-no-wrap text-right">
            {{ $date(item.alkaen) }}
          </td>
          <td class="text-no-wrap text-right">
            {{ $date(item.loppuen) }}
          </td>
          <td class="text-no-wrap">
            {{ item.vain_yhteenveto }}
          </td>
        </TableRow>
      </template>
    </v-data-table>

    <!-- Pagination -->
    <v-toolbar
      v-if="(pagesTotal > 1)"
      class="my-6 py-2 transparent"
      flat
    >
      <v-spacer />
      <Pagination
        v-model="pagination.page"
        :length="pagesTotal"
        :disabled="loading"
      />
      <v-spacer />
    </v-toolbar>
  </div>
</template>

<script>

import TransitionSlidePanel from '@/components/TransitionSlidePanel'
import {
  FormSearchRaportit,
  Pagination,
  ViewSelaaToolbar,
  ViewSelaaTopBarBase,
} from '@/components'
import ViewSelaaMixin from '@/mixins/ViewSelaaMixin'
import { TableHeadersRaportit } from '@/utils/tableHeaders'

export default {
  name: 'RaportitSelaa',
  components: {
    TransitionSlidePanel,
    FormSearchRaportit,
    Pagination,
    ViewSelaaToolbar,
    ViewSelaaTopBarBase,
  },
  mixins: [ViewSelaaMixin],
  data () {
    return {
      tableHeaders: TableHeadersRaportit,
    }
  },
  computed: {
    onkoLupaLuodaKuukausiraportti () {
      return this.$permission.checkSinglePerm('onlinerestapi.onlinerestapi_raporttilahetys')
    }
  },
  methods: {
    async lataaRaportti (raportti) {
      try {
        if (raportti.tila === 'Kesken') {
          this.$naytaVaroitusilmoitus(this.$t('common:raportitSelaa.reportInProgressWaitMsg'))
          return
        }

        const request = await this.$store.dispatch('raportitSelaa/lataaRaportti', raportti.id)
        if (!request.success) throw new this.$HttpError(request)

        const lataamisConfig = {
          fileData: request.result.body,
          headers: request.result.headers,
          filename: `${raportti.raportti}-${raportti.luotu.substr(0,10)}`,
        }
        this.$naytaTiedostonlataamisdialog(lataamisConfig)
      } catch (e) {
        this.$oletusVirheenkasittely(e)
      }
    },
    lisaaViewKohtaisetHaunSuodattimet (query) {
      if (this.searchTerms.alkaenAlkaen) query.append('alkaen_after', this.searchTerms.alkaenAlkaen)
      if (this.searchTerms.alkaenPaattyen) query.append('alkaen_before', this.searchTerms.alkaenPaattyen)
      if (this.searchTerms.paattyenAlkaen) query.append('paattyen_after', this.searchTerms.paattyenAlkaen)
      if (this.searchTerms.paattyenPaattyen) query.append('paattyen_before', this.searchTerms.paattyenPaattyen)
      if (this.searchTerms.luotuAlkaen) query.append('luotu_after', this.searchTerms.luotuAlkaen)
      if (this.searchTerms.luotuPaattyen) query.append('luotu_before', this.searchTerms.luotuPaattyen)

      if (this.searchTerms.paamies.length) {
        for (const pm of this.searchTerms.paamies) {
          query.append('paamiehet', pm.id)
        }
      }

      if (typeof this.searchTerms.tila === 'object') {
        query = this.kasitteleMoniosuodatin(query, this.searchTerms.tila)
      }

      if (this.searchTerms.vain_yhteenveto) query.append('vain_yhteenveto', true)

      return query
    },
  },
}

</script>

<style lang="scss" scoped>

</style>
