var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "raportit-kuukausiraportti-selaa" },
    [
      _c("ViewSelaaTopBarBase", {
        ref: "topBar",
        attrs: {
          "pikahaun-placeholder-teksti": _vm.$t(
            "common:raportitKuukausiraporttiSelaa.searchIdCreditor"
          ),
          "search-panel": false,
          "vuex-module-name": _vm.vuexModuleName,
        },
        on: {
          "open-search-panel": _vm.openSearchPanel,
          "close-search-panel": _vm.closeSearchPanel,
        },
      }),
      _c("ViewSelaaToolbar", {
        attrs: {
          "vuex-module-name": _vm.vuexModuleName,
          "item-partitiivi": "kuukausiraporttia",
          "alt-selaa-btn-config": {
            toName: "raportit-selaa",
            itemPartitiivi: "kertaraportteja",
          },
          "uusi-btn-config": {
            click: _vm.avaaUudenKuukausiraportinLuominen,
            itemName: "kuukausiraportti",
          },
        },
      }),
      _c("v-data-table", {
        staticClass: "v-data-table--striped",
        attrs: {
          headers: _vm.tableHeaders,
          items: _vm.items,
          "hide-default-footer": _vm.items.length < 10,
          "footer-props": {
            itemsPerPageOptions: [10, 25, 50, 100],
          },
          "multi-sort": true,
          "server-items-length": _vm.itemsTotal,
          options: _vm.pagination,
          loading: _vm.loading,
          "loading-text": "",
          "no-data-text": "",
          dense: _vm.tiivisNakyma,
        },
        on: {
          "update:options": function ($event) {
            _vm.pagination = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "progress",
            fn: function () {
              return [
                _c("v-progress-linear", {
                  attrs: { color: "primary", indeterminate: "" },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "item",
            fn: function (ref) {
              var item = ref.item
              return [
                _c(
                  "TableRow",
                  {
                    attrs: { clickable: false, data: item, fade: _vm.loading },
                  },
                  [
                    _c(
                      "td",
                      { staticClass: "text-no-wrap" },
                      [
                        _c("v-tooltip", {
                          attrs: { top: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function (ref) {
                                  var on = ref.on
                                  return [
                                    item.ikoni_ja_vari
                                      ? _c("StatusIcon", {
                                          attrs: {
                                            icon: item.ikoni_ja_vari.ikoni,
                                            size: _vm.tiivisNakyma
                                              ? "small"
                                              : "medium",
                                            "hover-icon": "edit",
                                            "bg-color": item.ikoni_ja_vari.vari,
                                            "event-listener": on,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.avaaKuukausiraportinMuokkaus(
                                                item
                                              )
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                  ]
                                },
                              },
                              {
                                key: "default",
                                fn: function () {
                                  return [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "common:raportitKuukausiraporttiSelaa.editReportSubmission"
                                          )
                                        )
                                      ),
                                    ]),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ],
                      1
                    ),
                    _c("td", { staticClass: "text-no-wrap" }, [
                      _vm._v(" " + _vm._s(item.id) + " "),
                    ]),
                    _c("td", { staticClass: "text-wrap" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            item.paamies.nimi + " (" + item.paamies.id + ")"
                          ) +
                          " "
                      ),
                    ]),
                    _c("td", { staticClass: "text-no-wrap" }, [
                      _vm._v(" " + _vm._s(item.raportti.nimi) + " "),
                    ]),
                    _c("td", { staticClass: "text-no-wrap" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            item.seuraava_ajoaika_display
                              ? item.seuraava_ajoaika_display
                              : "-"
                          ) +
                          " "
                      ),
                    ]),
                    _c("td", { staticClass: "text-no-wrap" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$date(item.aikavali.alkaen) +
                              " - " +
                              _vm.$date(item.aikavali.loppuen)
                          ) +
                          " "
                      ),
                    ]),
                    _c("td", { staticClass: "text-wrap" }, [
                      _vm._v(" " + _vm._s(_vm.emailUi(item.email)) + " "),
                    ]),
                    _c("td", { staticClass: "text-no-wrap" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            item.ajettu_viimeksi
                              ? _vm.$date(item.ajettu_viimeksi)
                              : "-"
                          ) +
                          " "
                      ),
                    ]),
                    _c("td", [
                      _c(
                        "div",
                        { staticClass: "d-flex flex-row" },
                        [
                          _c("v-tooltip", {
                            attrs: { top: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function (ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "div",
                                        _vm._g({}, on),
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                text: "",
                                                icon: "",
                                                small: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.avaaKuukausiraportinMuokkaus(
                                                    item
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { small: "" } },
                                                [_vm._v(" edit ")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "default",
                                  fn: function () {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "common:raportitKuukausiraporttiSelaa.editReportSubmission"
                                            )
                                          )
                                        ),
                                      ]),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                          _c("v-tooltip", {
                            attrs: { top: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function (ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "div",
                                        _vm._g({}, on),
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                text: "",
                                                icon: "",
                                                small: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.avaaKuukausiraportinMuokkaus(
                                                    item,
                                                    true
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { small: "" } },
                                                [_vm._v(" content_copy ")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "default",
                                  fn: function () {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "common:raportitKuukausiraporttiSelaa.copyToNewReport"
                                            )
                                          )
                                        ),
                                      ]),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                          _c("v-tooltip", {
                            attrs: { top: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function (ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "div",
                                        _vm._g({}, on),
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                text: "",
                                                icon: "",
                                                small: "",
                                                disabled:
                                                  item.paamies.id < 1000,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.avaaDialogLahetaNyt(
                                                    item
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { small: "" } },
                                                [_vm._v(" send ")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "default",
                                  fn: function () {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "common:raportitKuukausiraporttiSelaa.sendNow"
                                            )
                                          )
                                        ),
                                      ]),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                          _c("v-tooltip", {
                            attrs: { top: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function (ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "div",
                                        _vm._g({}, on),
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                text: "",
                                                icon: "",
                                                small: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.avaaDialogPoistaKuukausiraportti(
                                                    item
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { small: "" } },
                                                [_vm._v(" delete ")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "default",
                                  fn: function () {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "common:raportitKuukausiraporttiSelaa.deleteMonthReport"
                                            )
                                          )
                                        ),
                                      ]),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
              ]
            },
          },
        ]),
      }),
      _vm.pagesTotal > 1
        ? _c(
            "v-toolbar",
            { staticClass: "my-6 py-2 transparent", attrs: { flat: "" } },
            [
              _c("v-spacer"),
              _c("Pagination", {
                attrs: { length: _vm.pagesTotal, disabled: _vm.loading },
                model: {
                  value: _vm.pagination.page,
                  callback: function ($$v) {
                    _vm.$set(_vm.pagination, "page", $$v)
                  },
                  expression: "pagination.page",
                },
              }),
              _c("v-spacer"),
            ],
            1
          )
        : _vm._e(),
      _c(
        "CardDialog",
        {
          attrs: {
            title: _vm.kuukausiraporttiDialogOtsikko,
            "dialog-auki": _vm.kuukausiraporttiDialogAuki,
            "title-class": "px-12 py-6",
            "content-class": "px-12 py-4",
            width: "600",
          },
          on: { close: _vm.kasitteleKuukausiraporttilomakkeenSulkeminen },
        },
        [
          _c("FormKuukausiraportti", {
            attrs: {
              loading: _vm.kuukausiraporttiFormLoading,
              "on-auki": _vm.kuukausiraporttiDialogAuki,
              "muokattava-kuukausiraportti": _vm.muokattavaKuukausiraportti,
            },
            on: {
              "kuukausiraportti-tallennettu":
                _vm.kasitteleTallennettuKuukausiraportti,
              close: _vm.kasitteleKuukausiraporttilomakkeenSulkeminen,
            },
            model: {
              value: _vm.kuukausiraporttiFormData,
              callback: function ($$v) {
                _vm.kuukausiraporttiFormData = $$v
              },
              expression: "kuukausiraporttiFormData",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }