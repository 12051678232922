var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "raportit-uusi grey lighten-2" }, [
    _c(
      "div",
      { staticClass: "sticky" },
      [
        _c(
          "v-toolbar",
          {
            staticClass: "v-toolbar--sticky pl-6",
            attrs: { color: "primary", flat: "", tabs: "", dark: true },
          },
          [
            _c(
              "v-btn",
              { attrs: { to: { name: "raportit-selaa" }, icon: "" } },
              [_c("v-icon", [_vm._v("arrow_back")])],
              1
            ),
            _vm.$route.meta.title
              ? _c("v-toolbar-title", [
                  _vm._v(" " + _vm._s(_vm.$route.meta.title) + " "),
                ])
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "px-6 py-12 main-div" },
      [
        _vm.luotuRaportti.id
          ? [
              _c(
                "v-container",
                { staticClass: "max-width--1200 px-6 py-4 mx-auto mb-12" },
                [
                  _c(
                    "v-row",
                    { staticClass: "pa-6", attrs: { justify: "center" } },
                    [
                      _c("v-tooltip", {
                        attrs: { top: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function (ref) {
                                var on = ref.on
                                return [
                                  _c("StatusIcon", {
                                    attrs: {
                                      icon: "report",
                                      size: "large",
                                      "hover-icon": "file-download",
                                      "bg-color": !_vm.luotuRaportti.valmis
                                        ? "yellow darken-1"
                                        : "primary",
                                      "event-listener": on,
                                    },
                                    on: { click: _vm.lataaRaportti },
                                  }),
                                ]
                              },
                            },
                            {
                              key: "default",
                              fn: function () {
                                return [_c("span", [_vm._v("Lataa raportti")])]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          463861309
                        ),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "pa-6", attrs: { justify: "center" } },
                    [
                      !_vm.luotuRaportti.valmis
                        ? _c("span", { staticClass: "title" }, [
                            _vm._v(
                              " Raporttia luodaan. Ilmoitamme kun raportti on valmis. "
                            ),
                          ])
                        : [
                            _c("span", { staticClass: "title" }, [
                              _vm._v(" Raportti valmistunut: "),
                              _c("a", { on: { click: _vm.lataaRaportti } }, [
                                _vm._v(
                                  _vm._s(_vm.luotuRaportti.nimi) +
                                    " (id: " +
                                    _vm._s(_vm.luotuRaportti.id) +
                                    ")."
                                ),
                              ]),
                            ]),
                          ],
                    ],
                    2
                  ),
                  _c(
                    "v-row",
                    { staticClass: "pa-6", attrs: { justify: "center" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-2",
                          attrs: { large: "", to: { name: "raportit-selaa" } },
                        },
                        [_vm._v(" Palaa raporttien hakuun ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "ml-2",
                          attrs: { large: "", color: "success" },
                          on: { click: _vm.palautaAlkutilaan },
                        },
                        [_vm._v(" Luo uusi raportti ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          : [
              _c(
                "div",
                { staticClass: "paper px-6 py-12 mb-12" },
                [_c("FormRaportti", { ref: "formRaportti" })],
                1
              ),
              _c(
                "v-row",
                {
                  staticClass: "max-width--1200 px-6 py-4 mx-auto mb-12",
                  attrs: { justify: "center" },
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mx-5",
                      attrs: {
                        large: "",
                        color: "success",
                        disabled: !_vm.raporttilomake.paamies.length,
                      },
                      on: { click: _vm.luoRaportti },
                    },
                    [_vm._v(" Luo raportti ")]
                  ),
                ],
                1
              ),
            ],
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }