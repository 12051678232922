<template>
  <div class="raportit-uusi grey lighten-2">
    <!-- Top bar -->
    <div class="sticky">
      <v-toolbar
        class="v-toolbar--sticky pl-6"
        color="primary"
        flat
        tabs
        :dark="true"
      >
        <v-btn
          :to="{ name: 'raportit-selaa' }"
          icon
        >
          <v-icon>arrow_back</v-icon>
        </v-btn>
        <v-toolbar-title v-if="$route.meta.title">
          {{ $route.meta.title }}
        </v-toolbar-title>
      </v-toolbar>
    </div>


    <div class="px-6 py-12 main-div">
      <!-- Raportin luomisen jälkeinen valikko -->
      <template v-if="luotuRaportti.id">
        <v-container class="max-width--1200 px-6 py-4 mx-auto mb-12">
          <v-row
            justify="center"
            class="pa-6"
          >
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <StatusIcon
                  icon="report"
                  size="large"
                  :hover-icon="('file-download')"
                  :bg-color="!luotuRaportti.valmis ? 'yellow darken-1' : 'primary'"
                  :event-listener="on"
                  @click="lataaRaportti"
                />
              </template>
              <template v-slot:default>
                <span>Lataa raportti</span>
              </template>
            </v-tooltip>
          </v-row>
          <v-row
            justify="center"
            class="pa-6"
          >
            <span
              v-if="!luotuRaportti.valmis"
              class="title"
            >
              Raporttia luodaan. Ilmoitamme kun raportti on valmis.
            </span>
            <template v-else>
              <span class="title">
                Raportti valmistunut: <a @click="lataaRaportti">{{ luotuRaportti.nimi }} (id: {{ luotuRaportti.id }}).</a>
              </span>
            </template>
          </v-row>
          <v-row
            justify="center"
            class="pa-6"
          >
            <v-btn
              large
              :to="{ name: 'raportit-selaa' }"
              class="mr-2"
            >
              Palaa raporttien hakuun
            </v-btn>
            <v-btn
              large
              color="success"
              class="ml-2"
              @click="palautaAlkutilaan"
            >
              Luo uusi raportti
            </v-btn>
          </v-row>
        </v-container>
      </template>


      <!-- Raportin konfigurointi -->
      <template v-else>
        <div class="paper px-6 py-12 mb-12">
          <FormRaportti
            ref="formRaportti"
          />
        </div>

        <!-- Tallennus -->
        <v-row
          justify="center"
          class="max-width--1200 px-6 py-4 mx-auto mb-12"
        >
          <v-btn
            large
            color="success"
            class="mx-5"
            :disabled="!raporttilomake.paamies.length"
            @click="luoRaportti"
          >
            Luo raportti
          </v-btn>
        </v-row>
      </template>
    </div>
  </div>
</template>

<script>

import { mapState } from 'vuex'
import { Raportti } from '@/class/Raportti'
import { FormRaportti } from '@/components'

export default {
  name: 'RaportitUusi',
  components: {
    FormRaportti,
  },
  data () {
    return {}
  },
  computed: {
    ...mapState({
      luotuRaportti: state => state.raportitUusi.luotuRaportti,
      raporttilomake: state => state.raportitUusi.raporttilomake,
    }),
  },
  created () {
    this.palautaAlkutilaan()
    this.haeReskontraraportit()
  },
  methods: {
    async haeReskontraraportit () {
      try {
        this.$store.dispatch('raportitUusi/haeReskontraraportit')
      } catch (e) {
        this.$oletusVirheenkasittely(e, 'Raporttilistauksen haku epäonnistui.')
      }
    },
    async luoRaportti () {
      if (!this.$refs.formRaportti.validoiRaportti()) return
       try {
         if (this.raporttilomake.raportti.tyyppi === Raportti.TYYPPI_RESKONTRARAPORTTI) {
           await this.luoReskontraRaportti()
         } else if (this.raporttilomake.raportti.tyyppi === Raportti.TYYPPI_KTL_RAPORTTI) {
           await this.luoKtlRaportti()
         }
       } catch (e) {
        this.$oletusVirheenkasittely(e, 'Raportin luomisessa tapahtui virhe.')

        return
      }
    },
    async luoReskontraRaportti () {
      await this.$store.dispatch('raportitUusi/luoReskontraRaportti')
    },
    async luoKtlRaportti () {
      await this.$store.dispatch('raportitUusi/luoKtlRaportti')
    },
    palautaAlkutilaan () {
      this.$store.commit('raportitUusi/palautaAlkutilaan')
    },
    async lataaRaportti () {
      try {
        if (!this.luotuRaportti.valmis) {
          this.$naytaVaroitusilmoitus('Raportin luominen on kesken. Odota että raportti valmistuu.')
          return
        }

        const request = await this.$store.dispatch('raportitSelaa/lataaRaportti', this.luotuRaportti.id)
        if (!request.success) throw new this.$HttpError(request)

         const lataamisConfig = {
          fileData: request.result.body,
          headers: request.result.headers,
          filename: `${this.luotuRaportti.raportti}-${this.luotuRaportti.luotu.substr(0,10)}`,
        }
        this.$naytaTiedostonlataamisdialog(lataamisConfig)
      } catch (e) {
        this.$oletusVirheenkasittely(e)
      }
    },
  },
}

</script>

<style lang="scss" scoped>

.main-div {
  min-height: calc(100vh - 64px);
}

</style>
