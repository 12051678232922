<template>
  <div class="raportit-kuukausiraportti-selaa">
    <!-- Top bar -->
    <ViewSelaaTopBarBase
      ref="topBar"
      :pikahaun-placeholder-teksti="$t('common:raportitKuukausiraporttiSelaa.searchIdCreditor')"
      :search-panel="(false)"
      :vuex-module-name="vuexModuleName"
      @open-search-panel="openSearchPanel"
      @close-search-panel="closeSearchPanel"
    />

    <!-- Toolbar -->
    <ViewSelaaToolbar
      :vuex-module-name="vuexModuleName"
      item-partitiivi="kuukausiraporttia"
      :alt-selaa-btn-config="{ toName: 'raportit-selaa', itemPartitiivi: 'kertaraportteja' }"
      :uusi-btn-config="{ click: avaaUudenKuukausiraportinLuominen, itemName: 'kuukausiraportti' }"
    />

    <!-- Show items as table -->
    <v-data-table
      class="v-data-table--striped"
      :headers="tableHeaders"
      :items="items"
      :hide-default-footer="items.length < 10"
      :footer-props="{
        itemsPerPageOptions: [10, 25, 50, 100],
      }"
      :multi-sort="true"
      :server-items-length="itemsTotal"
      :options.sync="pagination"
      :loading="loading"
      loading-text=""
      no-data-text=""
      :dense="tiivisNakyma"
    >
      <template v-slot:progress>
        <v-progress-linear
          color="primary"
          indeterminate
        />
      </template>
      <template v-slot:item="{ item }">
        <TableRow
          :clickable="(false)"
          :data="item"
          :fade="loading"
        >
          <td class="text-no-wrap">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <StatusIcon
                  v-if="item.ikoni_ja_vari"
                  :icon="item.ikoni_ja_vari.ikoni"
                  :size="tiivisNakyma ? 'small' : 'medium'"
                  :hover-icon="('edit')"
                  :bg-color="item.ikoni_ja_vari.vari"
                  :event-listener="on"
                  @click="avaaKuukausiraportinMuokkaus(item)"
                />
              </template>
              <template v-slot:default>
                <span>{{ $t('common:raportitKuukausiraporttiSelaa.editReportSubmission') }}</span>
              </template>
            </v-tooltip>
          </td>
          <td class="text-no-wrap">
            {{ item.id }}
          </td>
          <td class="text-wrap">
            {{ `${item.paamies.nimi} (${item.paamies.id})` }}
          </td>
          <td class="text-no-wrap">
            {{ item.raportti.nimi }}
          </td>
          <td class="text-no-wrap">
            {{ item.seuraava_ajoaika_display ? item.seuraava_ajoaika_display : '-' }}
          </td>
          <td class="text-no-wrap">
            {{ `${$date(item.aikavali.alkaen)} - ${$date(item.aikavali.loppuen)}` }}
          </td>
          <td class="text-wrap">
            {{ emailUi(item.email) }}
          </td>
          <td class="text-no-wrap">
            {{ item.ajettu_viimeksi ? $date(item.ajettu_viimeksi) : '-' }}
          </td>
          <td>
            <div class="d-flex flex-row">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <div v-on="on">
                    <v-btn
                      text
                      icon
                      small
                      @click="avaaKuukausiraportinMuokkaus(item)"
                    >
                      <v-icon small>
                        edit
                      </v-icon>
                    </v-btn>
                  </div>
                </template>
                <template
                  v-slot:default
                >
                  <span>{{ $t('common:raportitKuukausiraporttiSelaa.editReportSubmission') }}</span>
                </template>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <div v-on="on">
                    <v-btn
                      text
                      icon
                      small
                      @click="avaaKuukausiraportinMuokkaus(item, true)"
                    >
                      <v-icon small>
                        content_copy
                      </v-icon>
                    </v-btn>
                  </div>
                </template>
                <template
                  v-slot:default
                >
                  <span>{{ $t('common:raportitKuukausiraporttiSelaa.copyToNewReport') }}</span>
                </template>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <div v-on="on">
                    <v-btn
                      text
                      icon
                      small
                      :disabled="item.paamies.id < 1000"
                      @click="avaaDialogLahetaNyt(item)"
                    >
                      <v-icon small>
                        send
                      </v-icon>
                    </v-btn>
                  </div>
                </template>
                <template
                  v-slot:default
                >
                  <span>{{ $t('common:raportitKuukausiraporttiSelaa.sendNow') }}</span>
                </template>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <div v-on="on">
                    <v-btn
                      text
                      icon
                      small
                      @click="avaaDialogPoistaKuukausiraportti(item)"
                    >
                      <v-icon small>
                        delete
                      </v-icon>
                    </v-btn>
                  </div>
                </template>
                <template
                  v-slot:default
                >
                  <span>{{ $t('common:raportitKuukausiraporttiSelaa.deleteMonthReport') }}</span>
                </template>
              </v-tooltip>
            </div>
          </td>
        </TableRow>
      </template>
    </v-data-table>

    <!-- Pagination -->
    <v-toolbar
      v-if="(pagesTotal > 1)"
      class="my-6 py-2 transparent"
      flat
    >
      <v-spacer />
      <Pagination
        v-model="pagination.page"
        :length="pagesTotal"
        :disabled="loading"
      />
      <v-spacer />
    </v-toolbar>

    <CardDialog
      :title="kuukausiraporttiDialogOtsikko"
      :dialog-auki="kuukausiraporttiDialogAuki"
      title-class="px-12 py-6"
      content-class="px-12 py-4"
      width="600"
      @close="kasitteleKuukausiraporttilomakkeenSulkeminen"
    >
      <FormKuukausiraportti
        v-model="kuukausiraporttiFormData"
        :loading="kuukausiraporttiFormLoading"
        :on-auki="kuukausiraporttiDialogAuki"
        :muokattava-kuukausiraportti="muokattavaKuukausiraportti"
        @kuukausiraportti-tallennettu="kasitteleTallennettuKuukausiraportti"
        @close="kasitteleKuukausiraporttilomakkeenSulkeminen"
      />
    </CardDialog>
  </div>
</template>

<script>

import {
  FormKuukausiraportti,
  Pagination,
  ViewSelaaToolbar,
  ViewSelaaTopBarBase,
} from '@/components'
import ViewSelaaMixin from '@/mixins/ViewSelaaMixin'
import { TableHeadersRaportitKuukausiraportti } from '@/utils/tableHeaders'

export default {
  name: 'RaportitKuukausiraporttiSelaa',
  components: {
    FormKuukausiraportti,
    Pagination,
    ViewSelaaToolbar,
    ViewSelaaTopBarBase,
  },
  mixins: [ViewSelaaMixin],
  data () {
    return {
      kuukausiraporttiFormData: {},
      kuukausiraporttiFormLoading: false,
      kuukausiraporttiDialogAuki: false,
      tableHeaders: TableHeadersRaportitKuukausiraportti,
      muokattavaKuukausiraportti: {},
    }
  },
  computed: {
    kuukausiraporttiDialogOtsikko () {
      return (
        !this.muokattavaKuukausiraportti.id || this.muokattavaKuukausiraportti.onKopioitavaPohja ?
        'Uusi kuukausiraportti' : 'Kuukausiraportin muokkaus'
      )
    },
  },
  watch: {
    kuukausiraporttiDialogAuki (val) {
      if (val) {
        this.handleKeyPressDisabloitu = true
      } else {
        this.handleKeyPressDisabloitu = false
      }
    }
  },
  methods: {
    avaaUudenKuukausiraportinLuominen () {
      this.muokattavaKuukausiraportti = {}
      this.kuukausiraporttiDialogAuki = true
    },
    avaaKuukausiraportinMuokkaus (kuukausiraportti, kopioi = false) {
      this.muokattavaKuukausiraportti = kuukausiraportti
      if (kopioi) this.muokattavaKuukausiraportti.onKopioitavaPohja = true
      this.kuukausiraporttiDialogAuki = true
    },
    kasitteleTallennettuKuukausiraportti (kuukausiraportti) {
      this.$naytaOnnistumisilmoitus('Kuukausiraportti tallennettu!')

      const index = this.items.findIndex((item => item.id === kuukausiraportti.id))
      if (index >= 0) {
        // Päivitetään muokattu kuukausiraportti listaukseen
        this.items.splice(index, 1, kuukausiraportti)
      } else {
        // Haetaan kaikki tulokset, kun ollaan tallennettu uusi raporttilähetys
        this.resetoiJaAsetaSearchQuery('')
      }

      this.kuukausiraporttiFormData = {}
      this.muokattavaKuukausiraportti = {}
    },
    kasitteleKuukausiraporttilomakkeenSulkeminen () {
      this.kuukausiraporttiDialogAuki = false
      this.kuukausiraporttiFormData = {}
      this.muokattavaKuukausiraportti = {}
    },
    async avaaDialogLahetaNyt (item) {
      const result = await this.$swal({
          title: 'Haluatko varmasti lähettää raportin nyt?',
          showCancelButton: true,
          focusCancel: true,
          confirmButtonText: 'Lähetä',
          cancelButtonText: 'Peruuta',
        })

      if (!result.value) return

      this.lahetaNyt(item)
    },
    async lahetaNyt (kuukausiraportti) {
      try {
        const request = await this.$store.dispatch(`${this.vuexModuleName}/lahetaNyt`, kuukausiraportti.id)

        if (!request.success) throw new this.$HttpError(request)

        this.$naytaOnnistumisilmoitus('Kuukausiraportti lähetetty. Huom. Seuraava lähetysaika on asetettu automaattisesti')

        const paivitettyKuukausiraportti = request.result.body
        const index = this.items.findIndex((item => item.id === paivitettyKuukausiraportti.id))
        if (index >= 0) {
          // Päivitetään kuukausiraportti listaukseen
          this.items.splice(index, 1, paivitettyKuukausiraportti)
        }
      } catch (e) {
        this.$oletusVirheenkasittely(e)
      }
    },
    async avaaDialogPoistaKuukausiraportti (kuukausiraportti) {
      const result = await this.$swal({
          title: 'Haluatko varmasti poistaa raportin?',
          showCancelButton: true,
          focusCancel: true,
          confirmButtonText: 'Poista',
          cancelButtonText: 'Peruuta',
        })

      if (!result.value) return

      this.poistaKuukausiraportti(kuukausiraportti)
    },
    async poistaKuukausiraportti (kuukausiraportti) {
      try {
        const request = await this.$store.dispatch(`${this.vuexModuleName}/poistaKuukausiraportti`, kuukausiraportti.id)

        if (!request.success) throw new this.$HttpError(request)

        this.$naytaOnnistumisilmoitus('Kuukausiraportti poistettu.')

        const index = this.items.findIndex((item => item.id === kuukausiraportti.id))
        if (index >= 0) {
          // Poistetaan kuukausiraportti listauksesta
          this.items.splice(index, 1)
        }
      } catch (e) {
        this.$oletusVirheenkasittely(e)
      }
    },
    emailUi (email) {
      return email.replace(/,/g, ', ')
    }
  },
}

</script>

<style lang="scss" scoped>

</style>
